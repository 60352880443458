import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  top: 0;
  padding: 1.5rem 2rem;
  background-color: #fff;
  width: 100%;

  &.is-sticky {
    position: sticky;
  }

  ${p => p.theme.layout.desktop} {
    padding: 2.5rem 4rem;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 1rem;
  align-items: center;

  ${p => p.theme.layout.desktop} {
    flex-direction: row;
    // margin: 2.5rem auto;
  }
`

const Item = styled(NavLink)`
  color: ${p => p.theme.color.primary};
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 1s;
  z-index: 1;
  display: block;
  position: relative;
  white-space: nowrap;
  padding: .75rem;

  ${p => p.theme.layout.desktop} {
    font-size: 1.4rem;
    font-weight: 600;
    flex: unset;
    padding: .75rem;
    text-align: unset;
  }

  &::after {
    content: '';
    height: 2px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 1.5rem);
    margin: 0 0.75rem;

    ${p => p.theme.layout.desktop} {
      // width: calc(100% - 1.5rem);
      // margin: 0 0.75rem;
    }
  }

  &.active,
  &:hover {
    color: ${p => p.theme.color.secondary};

    &::after {
      background-color: ${p => p.theme.color.secondary};
    }
  }
`

const Header = () => {
  const location = useLocation()

  return (
   <Wrapper className={location.pathname !== '/' ? 'is-sticky' : ''}>
    <List>
      <Item to={'/'} title={'home'} aria-label={'home'}>
        Home
      </Item>
      {/* <Item to={'/portfolio'} title={'portfolio'} aria-label={'portfolio'}>
        Portfolio
      </Item> */}
      <Item to={'/contact'} title={'contact'} aria-label={'contact'}>
        Say Hello
      </Item>
    </List>
   </Wrapper>
 );
};

export default Header;